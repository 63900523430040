<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title">
        筛选查询
      </div>
      <div>
        <div class="search-item">
          <span class="label">集团名称</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入集团名称"></el-input>
        </div>
        <div class="search-item">
          <span class="label">产品</span>
          <el-select v-model="searchParams.productId" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in productList"
              :key="item.productId"
              :label="item.productName"
              :value="item.productId">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">类型</span>
          <el-select v-model="searchParams.type" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in typeOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">到期时间</span>
          <el-date-picker
            size="small" style="width: 160px"
            v-model="searchParams.expireTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期" clearable>
          </el-date-picker>
        </div>
        <div class="search-item">
          <span class="label">剩余天数</span>
          <el-input v-model="searchParams.remainTimeStart" size="small" style="width: 100px" clearable></el-input>
          <span style="margin: 0 16px">至</span>
          <el-input v-model="searchParams.remainTimeEnd" size="small" style="width: 100px" clearable></el-input>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title">
        续期记录
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          prop="hotelName"
          label="酒店名称"
        >
          <template slot-scope="scope">
            <span :class="scope.row.orgType === 1?'b-1':'b-2'">{{scope.row.orgType === 1?'【门店】':'【集团】'}}</span>
            {{scope.row.hotelName}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="systemName"
          label="产品"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="产品版本"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="yearPrice"
          label="年费定价(元)"
          width="120"
        >
          <template slot-scope="scope">
            {{$commaNum(scope.row.yearPrice)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="expireTime"
          label="续期后到期时间"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          label="类型"
        >
          <template slot-scope="scope">
            <span class="status-tag" :class="'status' + scope.row.type">{{typeMap[scope.row.type]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="renewDuration"
          label="续期时长(天)"
          min-width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          label="续费金额(元)"
          min-width="120"
        >
          <template slot-scope="scope">
            {{scope.row.amount == -1 ? '-' : $commaNum(scope.row.amount)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="支付渠道"
        >
          <template slot-scope="scope">
            {{payMethodMap[scope.row.payMethod] || '-'}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="到期延迟(天)"
          min-width="120"
        >
          <template slot-scope="scope">
            {{scope.row.delayTime == -1 ? '-' : scope.row.delayTime}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="remainDay"
          label="剩余天数(天)"
          min-width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
          min-width="180"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="操作时间"
          width="180"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="operatorName"
          label="操作人"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showViewer(scope.row)" v-if="scope.row.type == 1">查看凭证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>

    <image-viewer :visible="imageVisible" :urlList="urlList" :on-close="closeViewer"></image-viewer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { pageMixin } from '@/mixins/pageMixin'
import { hotel_renew_log } from '@/api/hotel'
import { get_key_value } from '@/api/common'
import { get_product_list } from "@/api/product";
import imageViewer from '@/components/image-viewer'

export default {
  mixins: [pageMixin],
  components: {
    imageViewer
  },
  data() {
    return {
      InvoiceStatusColor: {},
      typeOption: [],
      typeMap: {},
      payMethodOption: [],
      payMethodMap: {},
      productList: [],
      searchParams: {
        search: '',
        productId: '',
        type: '',
        expireTime: '',
        remainTimeStart: '',
        remainTimeEnd: ''
      },
      imageVisible: false,
      urlList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'orgType'])
  },
  mounted() {
    this.getProduct()
    this.getOption()
    this.getList()
  },
  methods: {
    getProduct() {
      get_product_list()
        .then(res => {
          this.productList = res
        })
    },
    getOption() {
      get_key_value({
        type: 'HOTEL_RENEW_PAY_METHOD'
      }).then(res => {
        this.payMethodOption = res ? res.records : []
        this.payMethodMap = this.$arrayToMap(this.payMethodOption)
      })
      get_key_value({
        type: 'HOTEL_RENEW_TYPE'
      }).then(res => {
        this.typeOption = res ? res.records : []
        this.typeMap = this.$arrayToMap(this.typeOption)
      })
    },
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      const res = await hotel_renew_log(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    handleSearch() {
      if (Number(this.searchParams.remainTimeStart) <= Number(this.searchParams.remainTimeEnd)) {
        this.search()
      } else {
        this.$message.warning('请输入正确的筛选条件')
      }
    },
    showViewer(row) {
      console.log(row)
      this.imageVisible = true
      this.urlList = row.imgUrlList
    },
    closeViewer() {
      this.imageVisible = false
      this.urlList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.status-tag{
  font-size: 12px;
  width: 48px;
  line-height: 24px;
  display: inline-block;
  border-radius: 24px;
  &.status1{ // 续费
    color: rgba(230, 162, 60, 1);
    background: rgba(230, 162, 60, 0.14);
  }
  &.status2{ // 延期
    color:rgba(117, 157, 229, 1);
    background: rgba(117, 157, 229, 0.14);
  }
}
.b-1 {
  color: #486DB0;
}
.b-2 {
  color: #D48634;
}
</style>